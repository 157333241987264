import { useState } from "react";
import { isEmpty, isNil } from "lodash-es";
import { RxCollection } from "rxdb";
import { useAsyncEffect } from "./useAsyncEffect";
import {
  getCalculatedFieldId,
  getFieldFromFormVersions,
  getFormVersionForEntry,
  getInitialValue,
  getPinFormVersions,
  WidgetComponents,
} from "../utils/formUtil";
import { getDefaultValues } from "../utils/submissionUtil";
import { buildFormState } from "../state/useFieldState";
import { noopAsync } from "../utils/noop";
import { UniqueFieldId } from "../types/SubmissionState";
import { AbstractForm, FormVersion } from "../types/FormVersion";
import { Field, RememberedField } from "../types/Field";
import useDeviceInfo from "./useDeviceInfo";
import { getRememberedFieldId, removeWidgetVersionNumber } from "../utils/stringUtil";
import { FormState } from "../state/useSubmissionStore";
import { findFormVersions } from "../utils/FormEngine";
import logger from "../utils/logger";
import { EPOCH_DATE_ISO } from "../utils/dateUtil";

const knownWidgetIds = Object.keys(WidgetComponents);

const useInitialFormState = (
  submissionId?: string,
  formId?: string,
  formVersion?: FormVersion,
  fieldCollection?: RxCollection<Field> | null,
  rememberedFieldCollection?: RxCollection<RememberedField> | null,
): FormState | undefined => {
  const [formState, setFormState] = useState<FormState>();
  const { id: deviceId } = useDeviceInfo();

  useAsyncEffect(
    async () => {
      if (
        isNil(submissionId) || // HINT: could change with "save and new", creating new state
        isNil(formId) ||
        isNil(formVersion) ||
        isNil(fieldCollection) ||
        isNil(rememberedFieldCollection)
      ) {
        return;
      }

      // write new root fields to database first
      // This could be a first-time opening of a form, or copying form an older formVersion
      const existingFields = await fieldCollection.find().where("submissionId").eq(submissionId).exec();
      const rememberedFields = await rememberedFieldCollection.find().where("formId").eq(formId).exec();
      const formVersions = findFormVersions(formVersion);
      const newFields = buildNewFields(formVersion, submissionId, formId, deviceId, existingFields, rememberedFields);
      if (newFields.length > 0) {
        await fieldCollection.bulkUpsert(newFields);
      }

      // write nested fields for existing entries!
      const existingFieldsWithEntries = existingFields.filter((x) => x.entries.length > 0);
      await Promise.allSettled(
        existingFieldsWithEntries.flatMap((field) =>
          field.entries
            .filter((x) => !x.deleted)
            .map(async (entry) => {
              const nestedFormVersion = getFormVersionForEntry(field, entry, formVersions, formVersion.fieldProperties);
              if (isNil(nestedFormVersion)) {
                const formField = getFieldFromFormVersions(formVersions, field.formFieldId);
                const isPinWithoutForm =
                  removeWidgetVersionNumber(formField?.widget!) === "com.moreapps:pin" &&
                  isEmpty(getPinFormVersions(formField!, formVersion.fieldProperties));
                if (!isPinWithoutForm) {
                  logger.error("Couldn't find FormVersion for existing entry", null, {
                    extra: { widget: formField?.widget, entry: entry.id },
                  });
                }
                return;
              }
              const newNestedFields = buildNewFields(
                nestedFormVersion,
                submissionId,
                formId,
                deviceId,
                existingFields,
                rememberedFields,
                entry.id,
                field.id,
              );
              if (newNestedFields.length > 0) {
                await fieldCollection.bulkUpsert(newNestedFields);
              }
            }),
        ),
      );

      const fields = await fieldCollection.find().where("submissionId").eq(submissionId).exec();
      const defaultValues = await getDefaultValues(submissionId, fieldCollection);

      setFormState(buildFormState(formVersion, fields, submissionId, deviceId, defaultValues, rememberedFields));
    },
    noopAsync,
    [formVersion, submissionId, formId, rememberedFieldCollection],
  );

  return formState;
};

const buildNewFields = (
  formVersion: AbstractForm,
  submissionId: string,
  formId: string,
  deviceId: string,
  existingFields: Field[],
  rememberedFields: RememberedField[],
  entryId?: string,
  parentId?: UniqueFieldId,
): Field[] =>
  formVersion.fields
    .filter(({ uid, widget }) => {
      const knownWidget = knownWidgetIds.includes(removeWidgetVersionNumber(widget));
      const uniqueFieldId = getCalculatedFieldId(uid, submissionId, entryId, parentId);
      const existingField = existingFields.find((x) => x.id === uniqueFieldId);
      // `updatedBy` is set for fields pre-filled by tasks, or when mutated in the app.
      // The only way `updatedBy` is empty for an existing field, is when a Task is created.
      // Why? Because we create all fields in Hasura, even if they weren't pre-filled. Only the pre-filled ones have `updatedBy` set.
      const shouldSetInitialValue = isNil(existingField) || isNil(existingField.updatedBy);
      return knownWidget && shouldSetInitialValue;
    })
    .map((formField, index) => {
      const uniqueFieldId = getCalculatedFieldId(formField.uid, submissionId, entryId, parentId);

      // Replace default value with remembered data, if available
      const rememberedField = formField.properties.remember_input
        ? rememberedFields.find((x) => x.id === getRememberedFieldId(formField.uid, formId))
        : undefined;

      const widgetResult = getInitialValue(uniqueFieldId, formField, submissionId, rememberedField, entryId, parentId);
      return {
        id: uniqueFieldId,
        submissionId,
        updatedAt: EPOCH_DATE_ISO,
        formFieldId: widgetResult.meta.formFieldId,
        dataName: widgetResult.meta.dataName,
        widget: widgetResult.meta.widget,
        type: widgetResult.type,
        data: widgetResult.rawValue,
        parentId,
        entryId,
        deviceId,
        hidden: false,
        compressed: widgetResult.meta.compressed,
        entries: [],
        status: "draft",
        evaluatedRules: [],
        order: index,
        error: undefined, // FormEngine will set this on initial run
        _deleted: false,
      };
    });

export default useInitialFormState;
