import { produce } from "immer";
import { isEmpty, isNil, round } from "lodash-es";
import { deepEqual } from "fast-equals";
import { JSONPath } from "jsonpath-plus";
import { t } from "i18next";
import { FieldState, UniqueFieldId } from "../types/SubmissionState";
import { evaluateRules } from "./ruleEvaluationUtil";
import { AbstractForm, FormField, FormVersion, WidgetProperties } from "../types/FormVersion";
import {
  getCalculatedFieldId,
  getInitialValue,
  getWidgetProperties,
  highestOrder,
  validateFieldState,
  WidgetComponents,
} from "./formUtil";
import { getSanitizedTemplatedContent } from "./templateUtil";
import { getCalculationResult } from "./calculationUtil";
import { getTimeDifferenceResult, seconds } from "./timeUtil";
import { fieldStateToField, RememberedField, WidgetResult } from "../types/Field";
import { getPlaceholderDataNames, getRememberedFieldId, removeWidgetVersionNumber } from "./stringUtil";
import { getValueForType } from "./ruleUtil";
import { FormState } from "../state/useSubmissionStore";
import logger from "./logger";
import { getNestedEntries } from "./submissionUtil";
import { SubmissionFormData } from "../components/Form";
import { SubformEntry, WidgetSubformProperties } from "../components/widgets/WidgetSubform";
import { WidgetPinProperties } from "../components/widgets/WidgetPin";
import { nowToISO } from "./dateUtil";

export type FieldStateMutation =
  | FieldStateMutationUpdate
  | FieldStateMutationAddEntry
  | FieldStateMutationDeleteEntry
  | FieldStateMutationUpdateEntry;

type FieldStateMutationBase = {
  uniqueFieldId: UniqueFieldId;
};

type FieldStateMutationAddEntry = {
  type: "add_entry";
  entryId: string;
  formVersion?: AbstractForm;
  meta: Record<string, unknown>;
} & FieldStateMutationBase;

type FieldStateMutationDeleteEntry = {
  type: "delete_entry";
  entryId: string;
} & FieldStateMutationBase;

type FieldStateMutationUpdateEntry = {
  type: "update_entry";
  value: SubformEntry<unknown>;
} & FieldStateMutationBase;

type FieldStateMutationUpdate = {
  type: "update";
  value: WidgetResult<unknown>;
} & FieldStateMutationBase;

export type FormEngineRunStats = {
  total: number; // ms
  userMutations: number;
  autoMutations: {
    total: number;
    rules: number;
    calc: number;
    timeDiff: number;
    recursions: number;
  };
  descriptions: number;
  validations: number;
};

type FormEngineRunResult = {
  updatedState: FormState;
  mutatedFields: {
    upserted: FieldState<WidgetProperties, WidgetResult<unknown>>[];
    deleted: UniqueFieldId[];
  };
  stats: FormEngineRunStats;
};

export type FormEngineOptions = { validate: boolean };

type RunOptions = {
  validateAll: boolean;
  treatPendingUploadsAsInvalid: boolean;
  entryId?: string;
};

export const MAX_RECURSION_DEPTH = 100;
export const RECURSION_DEPTH_SEND_ERROR = 10;

export class FormEngine {
  private readonly submissionId: string;

  private readonly formId: string;

  private readonly deviceId: string;

  private readonly formVersion: FormVersion;

  private readonly formFields: Record<string, FormField<any>>;

  private readonly username: string;

  private readonly options: FormEngineOptions;

  private readonly placeholderDataNames: string[];

  constructor(
    submissionId: string,
    formId: string,
    deviceId: string,
    formVersion: FormVersion, // For rules and looking up linked subforms
    username: string, // Used in rules...
    options: FormEngineOptions,
  ) {
    this.submissionId = submissionId;
    this.formId = formId;
    this.deviceId = deviceId;
    this.formVersion = formVersion;
    this.username = username;
    this.options = options;
    this.placeholderDataNames = getPlaceholderDataNames(this.formVersion.settings.itemHtml);
    this.formFields = findFormVersions(this.formVersion)
      .flatMap((form) => form.fields)
      .reduce((acc, curr) => ({ [curr.uid]: curr, ...acc }), {});
  }

  run(
    formState: FormState,
    mutations: FieldStateMutation[],
    options: RunOptions = { validateAll: false, treatPendingUploadsAsInvalid: false },
  ): FormEngineRunResult {
    const stats: FormEngineRunStats = {
      total: 0,
      userMutations: 0,
      autoMutations: { total: 0, rules: 0, calc: 0, timeDiff: 0, recursions: 0 },
      descriptions: 0,
      validations: 0,
    };

    const mutatedFieldIds = new Set<UniqueFieldId>();
    const deletedFieldIds = new Set<UniqueFieldId>();
    const createdFieldIds = new Set<UniqueFieldId>();
    const skipValidation = new Set<UniqueFieldId>();
    const writeMutation = (uniqueFieldId: UniqueFieldId): void => {
      mutatedFieldIds.add(uniqueFieldId);
    };

    const updatedState = produce(formState, (draft) => {
      // 1) User mutations
      const startUserMutations = performance.now();
      mutations.forEach((mutation) => {
        switch (mutation.type) {
          case "update":
            this.#handleUpdate(mutation, draft.fields).forEach((uniqueFieldId) => writeMutation(uniqueFieldId));
            break;
          case "add_entry":
            this.#handleAddEntry(mutation, draft.fields, draft.rememberedFields).forEach((id, index) => {
              if (index === 0) {
                writeMutation(id); // parent field
              } else {
                createdFieldIds.add(id); // deleted (deeply) nested fields
              }
            });
            break;
          case "delete_entry":
            this.#handleDeleteEntry(mutation, draft.fields).forEach((id, index) => {
              if (index === 0) {
                writeMutation(id); // parent field
              } else {
                deletedFieldIds.add(id); // deleted (deeply) nested fields
              }
            });
            break;
          case "update_entry": {
            this.#handleUpdateEntry(mutation, draft.fields).forEach((uniqueFieldId) => writeMutation(uniqueFieldId));
            break;
          }
        }
      });
      const endUserMutations = performance.now();
      stats.userMutations = round(endUserMutations - startUserMutations, 1);

      // 2) Auto-mutations (rules, calculations, time difference)
      const evaluateAutoMutations = (depth: number = 0): void => {
        let hasChanges = false;

        // 2.1) rules
        const startRules = performance.now();
        evaluateRules(this.submissionId, draft.fields.map(fieldStateToField), this.formVersion, this.username).forEach(
          (evaluatedRule) => {
            const { value, hidden, ruleResults } = evaluatedRule;
            const field = findField(draft.fields, evaluatedRule.uniqueFieldId);
            if (isNil(field)) {
              logger.error("Can't find target field state for rule", null, {
                extra: { submissionId: this.submissionId, uniqueFieldId: evaluatedRule.uniqueFieldId },
              });
              return;
            }

            const valueForType = getValueForType(field.value.type, field.properties, value as Object);
            const hasValueChange = !isNil(valueForType);
            field.value.rawValue = hasValueChange ? valueForType : field.value.rawValue; // only update if value has data
            field.visible = !hidden;
            field.value.meta.hidden = !!hidden;
            field.value.meta.evaluatedRules = ruleResults;
            writeMutation(field.uniqueFieldId);
            hasChanges = hasChanges || hasValueChange;

            // if we only change visibility, we don't want to run validation.
            // However, we should write the mutation so the visibility change is persisted
            if (!hasValueChange) {
              skipValidation.add(field.uniqueFieldId);
            }
          },
        );
        const endRules = performance.now();
        stats.autoMutations.rules = round(stats.autoMutations.rules + round(endRules - startRules, 1), 1);

        // 2.2) calculations
        const startCalc = performance.now();
        draft.fields
          .filter((field) => removeWidgetVersionNumber(field.widget) === "com.moreapps.plugins:calculation")
          .map((field) => ({
            field,
            formField: this.formFields[field.uid],
          }))
          .map(({ field, formField }) => ({
            field,
            result: getCalculationResult(formField, draft.fields, field.value.meta.entryId),
          }))
          .forEach(({ field, result }) => {
            if (!deepEqual(result, field.value.rawValue)) {
              field.value.rawValue = result; // eslint-disable-line no-param-reassign
              writeMutation(field.uniqueFieldId);
              hasChanges = true;
            }
          });
        const endCalc = performance.now();
        stats.autoMutations.calc = round(stats.autoMutations.calc + round(endCalc - startCalc, 1), 1);

        // 2.3) time difference
        const startTimeDiff = performance.now();
        draft.fields
          .filter((field) => removeWidgetVersionNumber(field.widget) === "com.moreapps.plugins:timecalculation")
          .map((field) => ({
            field,
            formField: this.formFields[field.uid],
          }))
          .map(({ field, formField }) => ({
            field,
            result: getTimeDifferenceResult(formField, draft.fields, field.value.meta.entryId),
          }))
          .forEach(({ field, result }) => {
            if (!deepEqual(result, field.value.rawValue)) {
              field.value.rawValue = result; // eslint-disable-line no-param-reassign
              writeMutation(field.uniqueFieldId);
              hasChanges = true;
            }
          });
        const endTimeDiff = performance.now();
        stats.autoMutations = {
          ...stats.autoMutations,
          timeDiff: round(stats.autoMutations.timeDiff + round(endTimeDiff - startTimeDiff, 1), 1),
          recursions: depth,
        };

        // Max depth limit to prevent endless loop. In the future, this should be prevented when publishing FormVersion
        if (hasChanges && depth < MAX_RECURSION_DEPTH) {
          if (depth === RECURSION_DEPTH_SEND_ERROR) {
            logger.error(`FormEngine run recursed ${RECURSION_DEPTH_SEND_ERROR} times`, null, {
              extra: { submissionId: this.submissionId },
            });
          }
          evaluateAutoMutations(depth + 1);
        }
      };

      evaluateAutoMutations();
      stats.autoMutations.total = round(
        stats.autoMutations.calc + stats.autoMutations.rules + stats.autoMutations.timeDiff,
        1,
      );

      // 3) Descriptions
      const startDescriptions = performance.now();

      // 3.1) Submission descriptions
      const fieldsByDataName = this.placeholderDataNames.reduce((acc, dataName) => {
        const formField = this.formVersion.fields.find((field) => field.properties.data_name === dataName);
        if (!formField) {
          return acc;
        }
        const uniqueFieldId = getCalculatedFieldId(formField.uid, this.submissionId);
        const field = findField(draft.fields, uniqueFieldId);
        return field?.visible ? { ...acc, [formField.properties.data_name]: field.value } : acc;
      }, {} as SubmissionFormData);
      draft.description = getSanitizedTemplatedContent(this.formVersion.settings.itemHtml || "", fieldsByDataName); // eslint-disable-line no-param-reassign

      // 3.2) Entry descriptions
      draft.fields
        .filter((field) => !isEmpty(field.value.entries))
        .forEach((parentField) => {
          parentField.value.entries?.forEach((entry) => {
            const path = getEntryDescriptionPath(parentField, entry) ?? "";
            const placeholderDataNames = getPlaceholderDataNames(path);
            const subformFieldsByDataName = draft.fields
              .filter((field) => field.value.meta.entryId === entry.id)
              .filter((field) => !isNil(field.properties.data_name))
              .reduce((acc, curr) => {
                const dataName = curr.properties.data_name!;
                return placeholderDataNames.includes(dataName) && curr.visible
                  ? { ...acc, [dataName]: curr.value }
                  : acc;
              }, {} as SubmissionFormData);

            const newDescription = `${entry.meta.order}. ${getSanitizedTemplatedContent(path, subformFieldsByDataName, true)}`;
            if (newDescription !== entry.meta.description) {
              entry.meta.description = newDescription; // eslint-disable-line no-param-reassign
              writeMutation(parentField.uniqueFieldId);
            }
          });
        });

      const endDescriptions = performance.now();
      stats.descriptions = round(endDescriptions - startDescriptions, 1);

      // 4) Validation
      const startValidations = performance.now();
      if (this.options.validate) {
        const toValidateParents = new Set<string>(); // format as "parentId:entryId", as objects can't have equality check yet: https://stackoverflow.com/questions/29759480/how-to-customize-object-equality-for-javascript-set
        const undeletedFields = draft.fields.filter((field) => !field.deleted);
        undeletedFields
          .filter(
            (field) =>
              options.validateAll ||
              (mutatedFieldIds.has(field.uniqueFieldId) && !skipValidation.has(field.uniqueFieldId)),
          )
          .filter((field) => isNil(options.entryId) || field.value.meta?.entryId === options.entryId)
          .forEach((field) => {
            const error =
              validateFieldState(field, options.validateAll) ||
              validateFileStatus(field, options.treatPendingUploadsAsInvalid);

            if (field.error !== error) {
              field.error = error; // eslint-disable-line no-param-reassign
              writeMutation(field.uniqueFieldId);
              if (isNestedField(field)) {
                toValidateParents.add(`${field.value.meta.parentId!}:${field.value.meta.entryId!}`);
              }
            }
          });

        toValidateParents.forEach((hash) => {
          const [parentId, entryId] = hash.split(":");
          validateParents(parentId as UniqueFieldId, entryId, undeletedFields, writeMutation);
        });
      }
      const endValidations = performance.now();
      stats.validations = round(endValidations - startValidations, 1);
    });

    stats.total = round(stats.userMutations + stats.autoMutations.total + stats.descriptions + stats.validations, 1);
    if (stats.total > seconds(1)) {
      logger.error("FormEngine run took over 1 second", null, {
        extra: { stats, submissionId: this.submissionId, formId: this.formId },
      });
    }

    const upsertedFieldIds = [...createdFieldIds, ...mutatedFieldIds];

    return {
      updatedState: {
        ...updatedState,
        fields: updatedState.fields.filter((f) => !f.deleted), // don't keep deleted fields in state, to avoid memory-leaks of deleted subform entries
      },
      mutatedFields: {
        upserted: updatedState.fields.filter((field) => upsertedFieldIds.includes(field.uniqueFieldId)),
        deleted: [...deletedFieldIds],
      },
      stats,
    };
  }

  #handleUpdate(
    mutation: FieldStateMutationUpdate,
    fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  ): UniqueFieldId[] {
    const field = findField(fields, mutation.uniqueFieldId);
    if (isNil(field)) {
      logger.error("Can't find field state to update", null, {
        extra: { submissionId: this.submissionId, uniqueFieldId: mutation.uniqueFieldId },
      });
      return [];
    }
    field.deviceId = this.deviceId;
    field.value = mutation.value; // eslint-disable-line no-param-reassign
    if (field.value.meta.humanEdited) {
      field.value.updatedAt = nowToISO();
    }
    return [field.uniqueFieldId];
  }

  #handleAddEntry(
    mutation: FieldStateMutationAddEntry,
    fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
    rememberedFields: RememberedField[],
  ): UniqueFieldId[] {
    const { entryId, uniqueFieldId: parentId, formVersion, meta } = mutation;
    const field = findField(fields, parentId);
    if (isNil(field)) {
      logger.error("Can't add entry, field not found", null, {
        extra: { submissionId: this.submissionId },
      });
      return [];
    }

    const newFields: FieldState<WidgetProperties, WidgetResult<unknown>>[] = (formVersion?.fields || [])
      .filter(({ widget }) => Object.keys(WidgetComponents).includes(removeWidgetVersionNumber(widget)))
      .map((formField, order) => {
        // Replace default value with remembered data, if available
        const rememberedField = formField.properties.remember_input
          ? rememberedFields.find((f) => f.id === getRememberedFieldId(formField.uid, this.formId))
          : undefined;

        const uniqueFieldId = getCalculatedFieldId(formField.uid, this.submissionId, entryId, parentId);
        const value = getInitialValue(uniqueFieldId, formField, this.submissionId, rememberedField, entryId, parentId);
        value.updatedAt = nowToISO();
        value.meta.order = order;
        return {
          uid: formField.uid,
          uniqueFieldId,
          deviceId: this.deviceId,
          value,
          visible: true,
          widget: formField.widget,
          properties: getWidgetProperties(formField.widget, formField.properties),
          deleted: false,
        };
      });

    const order = highestOrder(field.value.entries) + 1;
    const newEntry: SubformEntry<unknown> = {
      id: entryId,
      submissionId: this.submissionId,
      meta: { ...meta, error: false, order, createdOn: nowToISO(), description: "" },
      deleted: false,
    };

    field.deviceId = this.deviceId;
    field.value.entries = [...(field.value.entries ?? []), newEntry]; // eslint-disable-line no-param-reassign
    field.value.meta.humanEdited = true;
    field.value.updatedAt = nowToISO();
    fields.push(...newFields);

    return [field.uniqueFieldId, ...newFields.map((f) => f.uniqueFieldId)];
  }

  #handleDeleteEntry(
    mutation: FieldStateMutationDeleteEntry,
    fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  ): UniqueFieldId[] {
    const field = findField(fields, mutation.uniqueFieldId);
    if (isNil(field)) {
      return [];
    }

    const { entryId } = mutation;
    const deletedFields: UniqueFieldId[] = [];

    field.deviceId = this.deviceId;
    field.value.meta.humanEdited = true;
    field.value.updatedAt = nowToISO();
    field.value.entries?.forEach((entry) => {
      if (entry.id === entryId) {
        entry.deleted = true; // eslint-disable-line no-param-reassign
      }
    });

    const entryIdsToDelete = getNestedEntries(fields, entryId);
    fields.forEach((f) => {
      if (!isNil(f.value.meta.entryId) && entryIdsToDelete.includes(f.value.meta.entryId)) {
        f.deleted = true; // eslint-disable-line no-param-reassign
        deletedFields.push(f.uniqueFieldId);
      }
    });

    return [field.uniqueFieldId, ...deletedFields];
  }

  #handleUpdateEntry(
    mutation: FieldStateMutationUpdateEntry,
    fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  ): UniqueFieldId[] {
    const field = findField(fields, mutation.uniqueFieldId);
    if (isNil(field)) {
      return [];
    }

    const { value } = mutation;

    field.deviceId = this.deviceId;
    field.value.meta.humanEdited = true;
    field.value.updatedAt = nowToISO();
    field.value.entries?.forEach((entry) => {
      if (entry.id === value.id) {
        // we don't allow overwriting `submissionId` and `id`. And to update `delete`, use the `delete_entry` mutation
        entry.meta = value.meta; // eslint-disable-line no-param-reassign
      }
    });

    return [field.uniqueFieldId];
  }
}

const findField = (
  fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  uniqueFieldId: UniqueFieldId,
): FieldState<WidgetProperties, WidgetResult<unknown>> | undefined =>
  fields.find((field) => field.uniqueFieldId === uniqueFieldId);

const findFieldEntry = (
  parentField: FieldState<WidgetProperties, WidgetResult<unknown>>,
  entryId: string,
): SubformEntry<unknown> | undefined => parentField.value.entries?.find((field) => field.id === entryId);

const validateParents = (
  parentId: UniqueFieldId,
  entryId: string,
  fields: FieldState<WidgetProperties, WidgetResult<unknown>>[],
  writeMutation: (uniqueFieldId: UniqueFieldId) => void,
): void => {
  const entryHasError = fields.some((field) => field.value.meta.entryId === entryId && !isEmpty(field.error));
  const parentField = findField(fields, parentId);
  if (isNil(parentField)) {
    logger.error("Can't validate parent field, because parent field can't be found", null, {
      extra: { parentId, entryId },
    });
    return;
  }

  const entry = findFieldEntry(parentField, entryId);
  if (isNil(entry)) {
    logger.error("Can't validate parent field, because entry can't be found", null, {
      extra: { parentId, entryId },
    });
    return;
  }

  if (entry.meta.error !== entryHasError) {
    entry.meta.error = entryHasError;
    writeMutation(parentId);
  }

  const parentFieldHasError = validateFieldState(parentField); // validates sub- or pin-field
  if (parentField.error !== parentFieldHasError) {
    parentField.error = parentFieldHasError;
    writeMutation(parentId);
  }

  // continue up the chain until no parent has been found
  if (isNestedField(parentField)) {
    validateParents(parentField.value.meta.parentId!, parentField.value.meta.entryId!, fields, writeMutation);
  }
};

const validateFileStatus = (
  field: FieldState<WidgetProperties, WidgetResult<unknown>>,
  treatPendingUploadsAsInvalid: boolean,
): string | undefined =>
  treatPendingUploadsAsInvalid && !isNil(field.value.meta.uploadStatus) && field.value.meta.uploadStatus !== "uploaded"
    ? t("VALIDATION_UPLOAD_IN_PROGRESS")
    : undefined;

const getEntryDescriptionPath = (
  parentField: FieldState<WidgetProperties, WidgetResult<unknown>>,
  entry: SubformEntry<any>,
): string | undefined => {
  if (parentField.value.meta.widget === "subform") {
    const properties = parentField.properties as WidgetSubformProperties;
    return properties.itemHtml;
  }
  if (parentField.value.meta.widget === "pin") {
    const properties = parentField.properties as WidgetPinProperties;
    const { target } = entry.meta.scope;
    return properties.pins?.find((pin) => target === pin.target_form_id || target === pin.form?.uid)?.itemMarkup;
  }
  return undefined;
};

const isNestedField = (field: FieldState<WidgetProperties, WidgetResult<unknown>>): boolean =>
  !isNil(field.value.meta.entryId) && !isNil(field.value.meta.parentId);

export const findFormVersions = (formVersion: AbstractForm): AbstractForm[] =>
  JSONPath({ path: "$..rules^", json: formVersion }); // Assumption: only FormVersions have a `rules` attribute. Could have used `triggers` or `integrations` too!
